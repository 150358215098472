import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { postMethod } from "../../services/base_service";
import { Apiurl } from "../../services/apiRoute";
import "../../css/sidebar.css";
import { showAlert } from "../../services/alert_service";
import { Header } from "../utils/NameString";

const Login = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    form: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = true;
    const newErrors = { ...errors };
    if (!formValue.email) {
      newErrors.email = "Email is required.";
      formIsValid = false;
    } else {
      newErrors.email = "";
    }
    if (!formValue.password) {
      newErrors.password = "Password is required.";
      formIsValid = false;
    } else {
      newErrors.password = "";
    }
    if (!formIsValid) {
      setErrors(newErrors);
      return;
    }
    try {
      setIsLoading(true);
      const response = await postMethod(Apiurl.login, formValue);
      if (response.data.status === 200) {
        setIsLoading(false);
        showAlert(response.data.message, "success");
        localStorage.setItem("token", response.data?.data?.token_key);
        localStorage.setItem("user_name", response.data?.data.user_name);
        localStorage.setItem(
          "reference_code",
          response.data?.data.reference_code != null
            ? response.data?.data.reference_code
            : ""
        );
        setFormValue({ email: "", password: "" });
        setErrors({ email: "", password: "", form: "" });
        navigate("/");
      } else if (response.data.status === 403) {
        showAlert(response.data.message, "error");
        setIsLoading(false);
      }
      else {
        setErrors({ ...errors, form: "Please enter valid credentials" });
        setIsLoading(false);
      }

    } catch (error) {
      setIsLoading(false);
      showAlert(error.message, "error");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
    setErrors({ ...errors, [name]: "", form: "" });
  };

  useEffect(() => {
    let keysToRemove = ["sponsercode", "username", "password", "verifyemail"];
    keysToRemove.forEach((key) =>
      localStorage.removeItem(key));
    const token = localStorage.getItem("token")
    if (token) {
      navigate('/')
    }
  })

  return (
    <div>
      <div id="body" className="theme-green">
        <div className="auth-main">
          <div className="auth_div vivify fadeIn">
            <div className="auth_brand">
              <div className="navbar-brand">
                <img
                  src={Header.Logo_image}
                  width="150"
                  className="d-inline-block mr-2"
                  alt="photos"
                />
              </div>
            </div>
            <div style={{ marginTop: "-55px" }}>{Header.Main_title}</div>
            <div className="card">
              <div className="header">
                <p className="lead">Login Account</p>
              </div>
              <div className="body">
                <form className="form-auth-small" onSubmit={handleSubmit}>
                  <div className="form-group c_form_group">
                    {isLoading ? <span className="loader"></span> : ""}
                    <label>Email</label>
                    <input
                      type="text"
                      value={formValue.email}
                      name="email"
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Please enter email address"
                    />
                  </div>
                  {errors.email && (
                    <p className="edit_hospital" style={{ marginRight: "26px" }}>
                      {errors.email}
                    </p>
                  )}
                  <div className="form-group c_form_group">
                    <label>Password</label>
                    <div style={{ display: "flex" }}>
                      <input
                        type={!showPassword ? "text" : "password"}
                        name="password"
                        value={formValue.password}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Please Enter Password"
                      // style={{position: "absolute"}}
                      />
                      <span
                        style={{
                          // position: "relative",
                          bottom: "118px",
                          right: "22px",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <i className="fa fa-eye-slash" aria-hidden="true"></i>
                        ) : (
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  {errors.password && (
                    <p className="edit_hospital">{errors.password}</p>
                  )}
                  {errors.form && <p style={{ color: "red" }}>{errors.form}</p>}
                  <button
                    type="submit"
                    className="btn btn-dark btn-lg btn-block"
                  >
                    LOGIN
                  </button>
                  <div className="bottom">
                    <span>
                      Don't have an account?
                      <Link to="/registration" style={{ marginLeft: "5px", fontWeight: 500 }}>Register</Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* <div className="animate_lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
