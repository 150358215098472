export const Apiurl = {
  // routePath: "http://192.168.28.6:3011/api/v1",
  routePath: "https://api.heartyrobo.com/api/v1",

  // imageRoutePath: "http://192.168.28.6:3011",
  imageRoutePath:"https://api.heartyrobo.com/",
  login: "/user/login",
  signup: "/user/signup",
  t_pc: "/t_pc/list",
  emailVerify: "/otp/verify",
  requestChange: "/account/status-change",
  resendOtp: "/otp/resend",
  addAccount: "/account/add",
  accountList: "/account/list",
  accountRequest: "/account_request/list",
  currencyList: "/currency/list",
  updateCurrency: "/add_and_update/currency",
  updateCurrencyList: "/currencyList",
  password_request: "/password_request",
  generate_referral_code: "/user/generate_referral_code",
  fcm_token: "/user/fcm_token",
  notification: "/user/notification/list",
  referralList: "/user/referral_list",
  accountlist: "/account_request/list",
  currencyhistry:"/currency_request/list"
};
