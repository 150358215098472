import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/Auth/Login";
import AccessControl from "./services/AccessControl";
import AccountRequests from "./components/pages/request/AccountRequests";
import Users from "./components/pages/Users";
import NotificationsList from "./components/pages/NotificationsList";
import ReferralList from "./components/pages/ReferralList";
import Dashboard from "./components/pages/Dashboard";
import Registration from "./components/Auth/Registration";
import UserVerification from "./components/Auth/UserVerification";
import Accounthistry from "./components/pages/Histry/Accounthistry";
import Currencieshistry from "./components/pages/Histry/Currencieshistry";
// import Header from "./components/pages/Landingsite/Header";
// import Footer from "./components/pages/Landingsite/Footer";
// import LandingPage from "./components/pages/Landingsite/LandingPage";
// import AllServices from "./components/pages/Landingsite/AllServices";
// import AlgoTrading from "./components/pages/Landingsite/AlgoTrading";
// import AutoBuySell from "./components/pages/Landingsite/AutoBuySell";
// import AboutUS from "./components/pages/Landingsite/AboutUS";

 function App() {
  // const isLoggedIn = localStorage.getItem("token");
  // const verifyemail = localStorage.getItem("verifyemail");

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/userverification" element={<UserVerification />}></Route>
          <Route path="/registration" element={<Registration />} />
          {/* <Route path="/header" element={<Header />} /> */}
          {/* <Route path="/footer" element={<Footer />} /> */}
          {/* <Route path="/" element={<LandingPage />} /> */}
          {/* <Route path="/allservices" element={<AllServices />} /> */}
          {/* <Route path="/algotrading" element={<AlgoTrading />} /> */}
          {/* <Route path="/autobuysell" element={<AutoBuySell />} /> */}
          {/* <Route path="/about" element={<AboutUS />} /> */}
          <Route element={<AccessControl />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/notifications" element={<NotificationsList />} />
            <Route path="/account-requests" element={<AccountRequests />} />
            <Route path="/referralslist" element={<ReferralList />} />
            <Route path="/Accounthistry" element={<Accounthistry />} />
            <Route path="/Currencieshistry" element={<Currencieshistry />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
