import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import toast, { Toaster } from "react-hot-toast";
import Pagination from "../../../services/Pagination";
import { Apiurl } from "../../../services/apiRoute";
import { getMethod } from "../../../services/base_service";
import { showAlert } from "../../../services/alert_service";
import debounce from "lodash/debounce";


const PasswordRequests = () => {
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [count, setCount] = useState(0);
  const [requests, setRequests] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [accList, setAccList] = useState([]);


  // get password request list
  const getPasswordRequests = async (search) => {
    try {
      setIsLoading(true);
      const response = await getMethod(
        `${Apiurl.currencyhistry}/?page=${currentPage}&limit=${10}&search=${search}`
      );
      setCount(response.data.total);
      setIsLoading(false);
      setRequests(response.data.data);
      setTotalPages(response.data.total_page);
    } catch (error) {
      setIsLoading(false);
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const delayedSearch = debounce(() => {
    console.log(searchQuery);
    setIsLoading(true);
    getPasswordRequests(searchQuery);
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);

  useEffect(() => {
    getPasswordRequests("");
  }, [currentPage]);

  return (
    <div>
      <Toaster />
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>
                Currency Histry: <span style={{ color: "red" }}>{count}</span>
                </h1>
              </div>
            </div>
          </div>
          <form>
            <div className="input-group">
              <span style={{ marginRight: "10px", marginTop: "5px" }}>
                Search :{" "}
              </span>
              <input
                type="text"
                name="search"
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1);
                }}
                className="form-control"
                placeholder="Account ID..."
                style={{ maxWidth:"20%"}}
              />
              <div className="input-group-append">
                <span className="input-group-text" id="search-mail">
                  <i className="fa fa-search"></i>
                </span>
              </div>
            </div>
          </form>
          <div className="row clearfix">
            <div className="col-sm-12">
              {isLoading ? (
                <span className="loader"></span>
              ) : requests.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Account Number</th>
                          <th>currency name</th>
                          {/* <th>Username</th> */}
                          <th>status</th>
                          <th>count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {requests.map((list, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>{i + 1}</td>
                              <td>{list?.terminal_id}</td>
                              <td>{list?.currency?.name}</td>
                              <td
                                style={{
                                  color: `${
                                    list?.status === "approved"
                                      ? "green"
                                      : "#d6c209"
                                  }`,
                                  fontStyle: "italic",
                                  fontWeight: "bold",
                                }}
                              >
                                {list?.status === "pending"
                                  ? "Pending"
                                  : "Approved"}
                              </td>
                              <td>{list?.count}</td>

                              <td>{list?.user?.usd}</td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRequests;
