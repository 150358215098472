import React, { useEffect, useState } from "react";
import { getMethod, postMethod, putMethod } from "../../services/base_service";
import { Apiurl } from "../../services/apiRoute";
import { Modal, ModalHeader } from "reactstrap";
import Pagination from "../../services/Pagination";
import debounce from "lodash/debounce";
import "../../css/sidebar.css";
import { showAlert } from "../../services/alert_service";
import Navbar from "./Navbar";
import toast, { Toaster } from "react-hot-toast";
import {
  getTokenData,
  onMessageListener,
  registerAndRequestPermission,
} from "../../services/firebaseInit";

const Dashboard = () => {
  const [accList, setAccList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [countValues, setCountValues] = useState([]);
  const [modal, setModal] = useState(false);
  const [currencyModal, setcurrencyModal] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const [password, setPassword] = useState();

  const [uidcurrency, setUidForCurrency] = useState();
  const [tidcurrency, setTidForCurrency] = useState();
  const [currencyData, setCurrencyData] = useState();

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [tpc, setTpc] = useState([]);
  const [formValue, setFormValue] = useState({
    terminal_id: 0,
    t_password: "",
    t_pc_id: 0,
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [powerOffDisabled, setPowerOffDisabled] = useState(true);

  const [notification, setNotification] = useState({ title: "", body: "" });
  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  const handleAccChange = (e) => {
    const { name, value } = e.target;
    const intValue = ["terminal_id", "t_pc_id"].includes(name)
      ? parseInt(value, 10)
      : value;
    setFormValue({ ...formValue, [name]: intValue });
    setErrors({ ...errors, [name]: "" });
  };

  const getAccounts = async () => {
    setIsLoading(true);
    await getMethod(Apiurl.accountList, {
      page: currentPage,
      limit: 8,
      search: searchQuery,
    })
      .then((res) => {
        setTotalPages(res.data.total_page);
        setAccList(res.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error" + error);
        showAlert("Error while fetching data", "error");
        setIsLoading(false);
      });
  };

  const getCurrencyType = async () => {
    setIsLoading(true);
    await getMethod(Apiurl.currencyList, {
      page: currentPage,
      pageSize: 5,
      search: searchQuery,
    })
      .then((res) => {
        setTotalPages(res.data.total_page);
        setCurrencyList(res.data.currencyList);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error" + error);
        showAlert("Error while fetching data", "error");
        setIsLoading(false);
      });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const delayedSearch = debounce(() => {
    getAccounts();
  }, 300);

  const validationRules = {
    terminal_id: "Account no is required",
    t_password: "Password is required",
    t_pc_id: "Server name is required",
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};
    Object.entries(validationRules).forEach(([key, message]) => {
      if (!formValue[key]) {
        newErrors[key] = message;
        formIsValid = false;
      }
    });
    setErrors(newErrors);
    return formIsValid;
  };

  const handleAccSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validateForm()) {
        const data = {...formValue, user_id:0, isAdmin:false}
        const response = await postMethod(Apiurl.addAccount, data);
        if (response.data.status === 403) {
          showAlert(response.data.message, "error");
        } else {
          showAlert(response.data.message, "success");
        }
        getAccounts();
        setFormValue({
          terminal_id: 0,
          t_password: "",
          t_pc_id: 0,
        });
        setErrors({});
        setModal(!modal);
      }
    } catch (err) {
      console.log("error" + err);
      showAlert("Internal Server Error", "error");
    }
  };

  const setAddModal = () => {
    setModal(true);
    setFormValue({});
  };

  const addCurrency = (uid, tid) => {
    setcurrencyModal(true);
    getCurrency(uid, tid);
    setUidForCurrency(uid);
    setTidForCurrency(tid);
  };

  const getCurrency = async (uid, tid) => {
    try {
      setIsLoading(true);
      const response = await getMethod(
        `${Apiurl.updateCurrencyList}/${tid}/${uid}`
      );
      if (response) {
        setIsLoading(false);
        if (response.data?.datalist && response.data?.datalist?.length > 0) {
          const initialCountValues = response.data?.datalist?.map(
            (currencyItem) => currencyItem.count
          );
          setCountValues(initialCountValues);
        } else {
          const zerosArray = currencyList?.map(() => 0);
          setCountValues(zerosArray);
        }
      }
      setCurrencyData(response.data?.datalist);
    } catch (error) {
      showAlert("Error fetching user currency data", "error");
      setIsLoading(false);
    }
  };

  const addCurrencySubmit = async (event) => {
    event.preventDefault();
    const isValidCount = countValues?.every(
      (countValue) => parseInt(countValue, 10) >= 0
    );
    if (!isValidCount) {
      console.error("Invalid count values. Please enter values >= 0.");
      return;
    }

    const allCurrencyData = {
      user_id: uidcurrency,
      terminal_id: tidcurrency,
      currency: currencyList?.map((currency, i) => ({
        id: currencyList[i]?.id !== "" ? currencyList[i]?.id : "",
        currencies_id: currency.id,
        count: parseInt(countValues[i], 10),
      })),
    };
    try {
      setIsLoading(true);
      const response = await postMethod(Apiurl.updateCurrency, allCurrencyData);
      if (response) {
        showAlert(response.data.message, "success");
        setIsLoading(false);
      } else {
        showAlert(response.data.message, "error");
      }
    } catch (error) {
      console.log("Error submitting currency data:", error);
    } finally {
      setIsLoading(false);
      setcurrencyModal(!currencyModal);
    }
  };

  const handleStatusChange = async (e, terminalId) => {
    e.preventDefault();
    await putMethod(`${Apiurl.requestChange}/${terminalId}`)
      .then((res) => {
        showAlert(res.data.data.message, "success");
        getAccounts();
      })
      .catch((error) => {
        console.log("error" + error);
        showAlert("Error while fetching data", "error");
      });
  };

  const getTpc = async () => {
    await getMethod(Apiurl.t_pc, {
      page: currentPage,
      limit: 50,
      search: searchQuery,
    })
      .then((res) => {
        setTpc(res.data.data);
      })
      .catch((error) => {
        console.log("Error" + error);
        showAlert("Error while fetching data", "error");
      });
  };

  const updateCountValue = (index, value) => {
    const newCountValues = [...countValues];
    newCountValues[index] = value;
    setCountValues(newCountValues);
  };

  const viewQrcode = (path) => {
    console.log("path: ", path);
    setPassword(path);
    setQrModal(true);
  };

  const requestPassword = async (terminal_id, type) => {
    if (type === "t_password") {
      try {
        const response = await postMethod(Apiurl.password_request, {
          terminal_id: terminal_id,
          type: type,
        });
        showAlert(response.data.message, "success");
      } catch (err) {
        console.log("T_password_request error: ", err);
      }
    } else {
      try {
        const response = await postMethod(Apiurl.password_request, {
          terminal_id: terminal_id,
          type: type,
        });
        showAlert(response.data.message, "success");
      } catch (err) {
        console.log("Read_password_request error: ", err);
      }
    }
  };

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);

  useEffect(() => {
    getTpc();
    getCurrencyType();
    // requestForToken();
  }, []);

  useEffect(() => {
    async function handleRegistration() {
      const registration = await registerAndRequestPermission();
      if (registration) {
        const token = await getTokenData(registration);
        console.log("FCM token:=> ", token);
        if (token !== null) {
          sendFcmToken(token);
        }
      }
    }
    handleRegistration();
  }, []);

  onMessageListener()
    .then((payload) => {
      console.log("payload===>", payload);
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      localStorage.setItem(
        "reference_code",
        payload?.data?.reference_code ? payload?.data?.reference_code : ""
      );
      getAccounts();
    })
    .catch((err) => console.log("failed: ", err));

  const sendFcmToken = async (token) => {
    try {
      const response = await putMethod(Apiurl.fcm_token, {
        firebase_token: token,
      });
    } catch (error) {
      showAlert(error.meaasge, "error");
    }
  };
  // useEffect(() => {
  //   setPowerOffDisabled((prevDisabled) => !prevDisabled);
  // }, [list.status]);

  return (
    <div>
      <Toaster />
      <Navbar />

      {/* add account */}

      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>ADD ACCOUNT</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleAccSubmit}>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Account_No.
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="number"
                            value={formValue.terminal_id}
                            name="terminal_id"
                            className="form-control"
                            onChange={handleAccChange}
                            style={{ width: "300px" }}
                            placeholder="Enter Account Number"
                          />
                        </div>
                      </div>
                      {errors.terminal_id && (
                        <p className="useredit_err">{errors.terminal_id}</p>
                      )}

                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          T_Password
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="text"
                            value={formValue.t_password}
                            name="t_password"
                            onChange={handleAccChange}
                            className="form-control"
                            style={{ width: "300px" }}
                            placeholder="Enter T Password"
                          />
                        </div>
                      </div>
                      {errors.t_password && (
                        <p className="useredit_err">{errors.t_password}</p>
                      )}
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Server_Name
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <select
                          name="t_pc_id"
                          type="text"
                          value={formValue.t_pc_id}
                          onChange={handleAccChange}
                          className="form-control input-height"
                          style={{ width: "300px", marginLeft: "8px" }}
                        >
                          <option value="">Select tpc...</option>
                          {tpc.map((tpc, i) => (
                            <option key={i} value={tpc.id}>
                              {tpc.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.t_pc_id && (
                        <p className="useredit_err">{errors.t_pc_id}</p>
                      )}

                      <div
                        className="form-group row"
                        style={{ marginTop: "10px" }}
                      >
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "30px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setModal(false);
                              setErrors({});
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>

      {/* update subscription */}

      <Modal
        isOpen={currencyModal}
        toggle={() => setcurrencyModal(!currencyModal)}
        style={{ width: "fit-content" }}
      >
        <ModalHeader toggle={() => setcurrencyModal(!currencyModal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>UPDATE SUBSCRIPTION</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={addCurrencySubmit}>
                      {currencyList?.map((currency, i) => (
                        <div className="form-group row" key={i}>
                          <label
                            className="col-sm-2 col-form-label"
                            style={{ marginRight: "50px" }}
                          >
                            {currency.name}
                          </label>
                          <div className="col-md-6 col-sm-10">
                            <input
                              type="text"
                              value={countValues[i]}
                              onChange={(e) =>
                                updateCountValue(i, e.target.value)
                              }
                              className="form-control"
                              style={{ width: "100px", borderRadius: "8px" }}
                              placeholder=""
                            />
                          </div>
                        </div>
                      ))}

                      <div
                        className="form-group row"
                        style={{ marginTop: "15px" }}
                      >
                        <label className="col-sm-2 col-form-label"></label>
                        <div
                          className="col-md-6 col-sm-10"
                          style={{ display: "flex" }}
                        >
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "30px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setcurrencyModal(false);
                              // setErrors({});
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>

      {/* Qr modal */}

      <Modal
        isOpen={qrModal}
        toggle={() => setQrModal(!qrModal)}
        style={{ width: "fit-content" }}
      >
        <ModalHeader toggle={() => setQrModal(!qrModal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Your Password </h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <img
                      src={`${Apiurl.imageRoutePath}${password}`}
                      style={{ width: "435px" }}
                      alt="account password"
                    />
                    <div
                      className="btn btn-secondary"
                      style={{
                        backgroundColor: "grey",
                        marginLeft: "40%",
                        borderRadius: "5px",
                        marginTop: "10px",
                      }}
                      onClick={() => {
                        setQrModal(false);
                      }}
                    >
                      Close
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Live Accounts : </h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                }}
              >
                <div className="card" style={{ width: "70%" }}>
                  <div className="body">
                    <form>
                      <div className="input-group">
                        <span style={{ marginRight: "10px", marginTop: "5px" }}>
                          Search :{" "}
                        </span>
                        <input
                          type="text"
                          name="search"
                          onChange={(e) => {
                            setSearchQuery(e.target.value);
                            setCurrentPage(1);
                          }}
                          className="form-control"
                          placeholder="Account ID..."
                          style={{ maxWidth: "20%" }}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text" id="search-mail">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-default btn-sm"
                    style={{
                      backgroundColor: "#22a63c",
                      borderRadius: "5px",
                      marginRight: "5px",
                      color: "white",
                    }}
                    title="Add Account"
                    onClick={() => setAddModal()}
                  >
                    Add Account
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <table
                  className="table table-hover table-custom spacing5 font_weight"
                  style={{ fontWeight: 500 }}
                >
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Account Number</th>
                      <th>Server Name</th>
                      <th>USD</th>
                      <th>AUD</th>
                      <th>EUR</th>
                      <th>Status</th>
                      <th>T Password</th>
                      <th>R Password</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accList &&
                      accList?.map((list, i) => (
                        <React.Fragment key={i}>
                          <tr>
                            <td>{i + 1}</td>
                            <td>{list?.terminal_id}</td>
                            <td>{list?.t_pc?.name}</td>
                            <td>
                              {list?.user_currencies?.length === 0
                                ? 0
                                : list?.user_currencies?.find(
                                    (c) => c.currency.name === "USD"
                                  )?.count
                                ? ` ${
                                    list?.user_currencies.find(
                                      (c) => c.currency.name === "USD"
                                    )?.count
                                  }`
                                : ""}
                            </td>
                            <td>
                              {list?.user_currencies?.length === 0
                                ? 0
                                : list?.user_currencies?.find(
                                    (c) => c.currency.name === "AUD"
                                  )?.count
                                ? ` ${
                                    list?.user_currencies.find(
                                      (c) => c.currency.name === "AUD"
                                    )?.count
                                  }`
                                : ""}
                            </td>
                            <td>
                              {list?.user_currencies?.length === 0
                                ? 0
                                : list?.user_currencies?.find(
                                    (c) => c.currency.name === "EUR"
                                  )?.count
                                ? ` ${
                                    list?.user_currencies.find(
                                      (c) => c.currency.name === "EUR"
                                    )?.count
                                  }`
                                : ""}
                            </td>
                            <td
                              style={{
                                color: `${
                                  list?.status === "approved"
                                    ? "green"
                                    : "#d6c209"
                                }`,
                              }}
                            >
                              {list?.status === "pending"
                                ? "Pending"
                                : "Approved"}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-default btn-sm"
                                style={{
                                  backgroundColor:
                                    list?.t_pass_path &&
                                    list?.status === "approved"
                                      ? "#17a2b8"
                                      : "#6c757d",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                  color: "white",
                                }}
                                title="View"
                                onClick={() => viewQrcode(list?.t_pass_path)}
                                disabled={
                                  !list?.t_pass_path ||
                                  list?.status === "pending"
                                }
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="btn btn-default btn-sm"
                                style={{
                                  backgroundColor:
                                    list?.status === "pending"
                                      ? "#6c757d"
                                      : "#bda01ed6",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                  color: "white",
                                }}
                                title="Request"
                                onClick={() =>
                                  requestPassword(
                                    list?.terminal_id,
                                    "t_password"
                                  )
                                }
                                disabled={list?.status === "pending"}
                              >
                                Request
                              </button>
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-default btn-sm"
                                style={{
                                  backgroundColor:
                                    list?.t_read_only_pass_path &&
                                    list?.status === "approved"
                                      ? "#17a2b8"
                                      : "#6c757d",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                  color: "white",
                                }}
                                title="View"
                                onClick={() =>
                                  viewQrcode(list?.t_read_only_pass_path)
                                }
                                disabled={
                                  !list?.t_read_only_pass_path ||
                                  list?.status === "pending"
                                }
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="btn btn-default btn-sm"
                                style={{
                                  backgroundColor:
                                    list?.status === "pending"
                                      ? "#6c757d"
                                      : "#bda01ed6",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                  color: "white",
                                }}
                                title="Request"
                                onClick={() =>
                                  requestPassword(
                                    list?.terminal_id,
                                    "terminal_read_only_password"
                                  )
                                }
                                disabled={list?.status === "pending"}
                              >
                                Request
                              </button>
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-default btn-sm"
                                style={{
                                  backgroundColor:
                                    list?.status === "pending"
                                      ? "#6c757d"
                                      : "#17a2b8",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                  color: "white",
                                }}
                                title="Add Currency"
                                onClick={() =>
                                  addCurrency(list?.user_id, list?.terminal_id)
                                }
                                disabled={list?.status === "pending"}
                              >
                                <i className="fa fa-usd" aria-hidden="true"></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-default btn-sm"
                                style={{
                                  backgroundColor:
                                    list?.stop_request_status === "requested" ||
                                    list?.status === "pending"
                                      ? "#6c757d"
                                      : "#17a2b8",
                                  borderRadius: "5px",
                                  marginRight: "5px",
                                  color: "white",
                                }}
                                title="Start/Stop Account"
                                disabled={
                                  list?.stop_request_status == null &&
                                  (list?.stop_request_status === "requested" ||
                                    list?.status === "pending")
                                }
                                onClick={(e) =>
                                  handleStatusChange(e, list.terminal_id)
                                }
                              >
                                <i className="fa fa-power-off"></i>
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
