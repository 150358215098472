import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { postMethod } from "../../services/base_service";
import { Apiurl } from "../../services/apiRoute";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../../services/alert_service";

const UserVerification = () => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [disableResend, setDisableResend] = useState(true);
  const [timer, setTimer] = useState(30);
  const [otpSent, setOtpSent] = useState(true);
  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");
  const sponsercode = localStorage.getItem("sponsercode");
  const verifyemail = localStorage.getItem("verifyemail");
  const firstThreeLetters = verifyemail?.substring(0, 3);
  const atIndex = verifyemail?.indexOf("@");
  const maskedEmail = firstThreeLetters + "...@" + verifyemail?.substring(atIndex + 1);
  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (disableResend) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [disableResend]);

  useEffect(() => {
    if (timer === 0) {
      setDisableResend(false);
      setTimer(30);
    }
  }, [timer]);

  useEffect(() => {
    if (otpSent) {
      setDisableResend(true);
    }
  }, [otpSent]);

  useEffect(() => {
    if (!otpSent) {
      handleResend();
    }
  }, []);

  useEffect(() => {
    if (!verifyemail) {
      navigate('/login')
    }
  }, [verifyemail]);


  // useEffect(() => {
  //   const handleBackButton = (event) => {
  //     if (window.history.back()) {
  //       const confirmLeave = window.confirm("Are you sure you want to leave this page? Your verification progress will be lost.");
  //       if (confirmLeave) {
  //         localStorage.removeItem("verifyemail");
  //       } else {
  //         event.preventDefault();
  //       }
  //     }
  //   };
  //   window.addEventListener("popstate", handleBackButton);
  //   return () => {
  //     window.removeEventListener("popstate", handleBackButton);
  //   };
  // }, []);

  const handleVerify = async () => {

    try {
      if (otp.length < 4) {
        setError("please enter valid OTP")
        return
      }
      const response = await postMethod(Apiurl.emailVerify, {
        email: verifyemail,
        otp: otp,
        sponsor_code: sponsercode,
        user_name: username,
        password: btoa(password),

      });
      showAlert(response.data.message, "success");
      if (response.data.status === 403) {
        showAlert(response.data.message, "error");
      } else {
        let keysToRemove = ["sponsercode", "username", "password", "verifyemail"];
        keysToRemove.forEach((key) =>
          localStorage.removeItem(key));
        navigate("/login");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleOtpChange = (otp) => {
    setOtp(otp);
    setError("");
  };

  const handleResend = async () => {
    try {
      const response = await postMethod(Apiurl.resendOtp, {
        email: verifyemail,
      });
      showAlert(response.data.message, "success");
      setDisableResend(true);
      setOtpSent(true);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div>
      <div className="App" style={{ marginTop: "50px" }}>
        <h5>
          Please enter a verification OTP sent to your email address{" "}
          <span style={{ color: "#3ea4c5" }}> {maskedEmail}</span>
        </h5>
        <OtpInput
          value={otp}
          onChange={handleOtpChange}
          numInputs={4}
          renderSeparator={<span> </span>}
          inputType="tel"
          containerStyle={{ display: "unset" }}
          inputStyle={{ width: "3rem", height: "3.5rem" }}
          renderInput={(props) => <input {...props} className="otp-input" />}
        />
        <div className="btn-container">
          {!disableResend && (
            <button
              style={{
                marginRight: "10px",
                backgroundColor: "lightblue",
              }}
              onClick={handleResend}
            >
              Resend OTP
            </button>
          )}
          <button
            style={{ backgroundColor: "lightblue" }}
            onClick={handleVerify}
          >
            Verify OTP
          </button>
          {disableResend && <span style={{ marginLeft: '7px' }}>Resend OTP in {timer}s</span>}
        </div>
        {error &&
          <p className="text-danger" style={{ marginTop: "7px" }}>{error}</p>
        }
      </div>
    </div>
  );
};

export default UserVerification;
