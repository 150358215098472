import React, { useEffect, useState } from "react";
import "../../css/sidebar.css";
import { NavLink } from "react-router-dom";
import { Header } from "../utils/NameString.js";

const Sidebar = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  function toggleSidebar() {
    setSidebarVisible(!isSidebarVisible);
  }

  function toggleSubMenu(event) {
    event.preventDefault();
    const parentLi = event.target.closest("li");
    const submenu = parentLi.querySelector(".submenu");
    submenu.style.display =
      submenu.style.display === "block" ? "none" : "block";
  }

  const handleSidebarClose = () => {
    if (width < 1270) setSidebarVisible(!isSidebarVisible);
  };

  const closeSidebar = () => {
    if (width <= 1270) {
      setSidebarVisible(false);
    } else {
      setSidebarVisible(true);
    }
  };

  const close = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  useEffect(() => {
    closeSidebar();
    close();
  }, [width]);

  return (
    <div>
      {isSidebarVisible ? (
        <div
          id="left-sidebar"
          className="sidebar light_active sidebar_border scroll_sidebar"
        >
          <div className="navbar-brand">
           
              <img
                src={Header.Logo_image}
                alt="hearty_Logo"
                className="img-fluid logo"
              />
              <span style={{marginBottom:'10px', marginLeft:"-10px"}} >{Header.Main_title}</span>
            
            <button
              type="button"
              className=" btn btn-sm float-right show_button"
              onClick={toggleSidebar}
            >
              <i className="fa fa-close"></i>
            </button>
          </div>
          <div className="user-account">
            <div className="user_div">
              <img
                src={Header.User_image}
                className="user-photo"
                alt="User Profile"
              />
            </div>
            <div className="dropdown">
              <span>{Header.Greetings}</span>
              <div
                className="user-name"
                data-toggle="dropdown"
              >
                <strong>User</strong>
              </div>
            </div>
          </div>
          <nav
            id="left-sidebar-nav"
            className="sidebar-nav"
            style={{ fontFamily: "Nunito" }}
          >
            <ul
              id="main-menu"
              className="metismenu animation-li-delay collapsing.in"
            >
              <li className="header">{Header.Sub_title}</li>

                <li>
                  <NavLink
                    to="/"
                    className="submenu-link"
                    onClick={handleSidebarClose}
                  >
                    <i className="fa-solid fa-gauge"></i>
                    Dashboard
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to="/account-requests"
                    className="submenu-link activemenu"
                    onClick={handleSidebarClose}
                  >
                    <i className="fa fa-clock-o"></i>
                    Account Requests
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    to="/notifications"
                    className="submenu-link"
                    onClick={handleSidebarClose}
                  >
                    <i className="fa fa-commenting"></i>
                    Notifications
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/referralslist"
                    className="submenu-link"
                    onClick={handleSidebarClose}
                  >
                   <i className="fa-solid fa-user-plus"></i>
                    Reference List
                  </NavLink>
                
                </li>
                <li>
                <a href="" className="has-arrow" onClick={toggleSubMenu}>
                <i className="fa-solid fa-clock-rotate-left"></i>
                  <span>History</span>
                </a>
                <ul className="submenu">
                  <li>
                    <NavLink
                      to="/Accounthistry"
                      className="submenu-link"
                      onClick={handleSidebarClose}
                    >
                      <i className="fa-solid fa-user"></i>
                      Accouny History
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/currencieshistry"
                      className="submenu-link"
                      onClick={handleSidebarClose}
                    >
                     <i className="fa-solid fa-money-bill"></i>
                      Currency History
                    </NavLink>
                  </li>
                </ul>
              </li>
              

              {/* <li>
                <a href="" className="has-arrow" onClick={toggleSubMenu}>
                  <i className="fa fa-cogs"></i>
                  <span>Settings</span>
                </a>
                <ul className="submenu">
                  <li>
                    <a href="" className="has-arrow" onClick={toggleSubMenu}>
                      <i className="fa fa-book"></i>
                      <span>Appointment Types</span>
                    </a>
                    <ul className="submenu">
                      <li>
                        <NavLink
                          to="/alltypes"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          All Types
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/addtypes"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Add Types
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a href="" className="has-arrow" onClick={toggleSubMenu}>
                      <i className="fa fa-user-md"></i>
                      <span>Roles</span>
                    </a>
                    <ul className="submenu">
                      <li>
                        <NavLink
                          to="/roles"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          All Roles
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/addroles"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Add Roles
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li> */}
            </ul>
          </nav>
        </div>
      ) : (
        <div className="toggle_sidebar_button">
          <button
            type="button"
            className="btn-toggle-offcanvas show_button"
            onClick={toggleSidebar}
          >
            <i className="fa fa-align-left"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
