import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Pagination from "../../services/Pagination";
import { Apiurl } from "../../services/apiRoute";
import { getMethod } from "../../services/base_service";
import { showAlert } from "../../services/alert_service";

const NotificationsList = () => {
  const [notifications, setNotifications] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  // get account request list
  const getNotificationsList = async () => {
    try {
      setIsLoading(true);
      const response = await getMethod(
        `${Apiurl.notification}/?page=1&limit=10`
      );
      console.log("response----------", response.data.data);
      setNotifications(response.data.data);
      setTotalPages(response.data.total_page);
      setIsLoading(false);
    } catch (error) {
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    getNotificationsList();
  }, [currentPage]);

  return (
    <div>
      <Navbar />

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Notifications</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              {isLoading ? (
                <span className="loader"></span>
              ) : notifications.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {notifications.map((list, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>{list.title}</td>
                              <td>{list?.desc}</td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsList;
