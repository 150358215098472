import React, { useEffect, useState } from "react";
import logo from "../../images/StockLogo.png";
import { Link, useNavigate } from "react-router-dom";
import { postMethod } from "../../services/base_service";
import { Apiurl } from "../../services/apiRoute";
import { showAlert } from "../../services/alert_service";
import { Header } from "../utils/NameString";

const Registration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    sponsor_code: "",
    user_name: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  useEffect(() => {
    // localStorage.removeItem("sponsercode");
    // localStorage.removeItem("username");
    // localStorage.removeItem("password");
    // localStorage.removeItem("verifyemail");
    let keysToRemove = ["sponsercode", "username", "password", "verifyemail"];
    keysToRemove.forEach((key) =>
      localStorage.removeItem(key));
    const token = localStorage.getItem("token")
    if (token) {
      window.location.href = "/";
    }
  })

  const validationRules = {
    // sponsor_code: "Please enter sponsor code ",
    user_name: "Name is required",
    email: "Email is required",
    password: "Password is required",
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};
    Object.entries(validationRules).forEach(([key, message]) => {
      if (!formValue[key]) {
        newErrors[key] = message;
        formIsValid = false;
      }
    });
    const emailRegex = /^[\w\+\-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (formValue.email && !emailRegex.test(formValue.email)) {
      newErrors.email = "Enter a valid email address.";
      formIsValid = false;
    }
    const passwordRegex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    if (formValue.password && !passwordRegex.test(formValue.password)) {
      newErrors.password =
        "Password must be at least 8 characters long and contain at least one letter, one number, and one special character.";
      formIsValid = false;
    }
    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validateForm()) {
        setIsLoading(true);
        const data = formValue.email
        console.log('data: ', data)
        const response = await postMethod(Apiurl.signup, { email: data });
        if (response.data.status === 403) {
          showAlert(response.data.message, 'error')
          setIsLoading(false);
        } else {
          localStorage.setItem("verifyemail", formValue.email);
          localStorage.setItem("sponsercode", formValue.sponsor_code);
          localStorage.setItem("username", formValue.user_name);
          localStorage.setItem("password", formValue.password);
          setIsLoading(false);
          setFormValue({
            sponsor_code: "",
            user_name: "",
            email: "",
            password: "",
          });
          setErrors({});
          showAlert(response.data.message, "success");
          navigate("/userverification");
        }
      }
    } catch (error) {
      console.log("Error:", error);
      showAlert("Error in registration due to server error", "error")
      setIsLoading(false);
      //   if (error.error === "Record Already Exists") {
      //     setErrors({ form: "User with this mobile number already exists." });
      //   } else
      //     setErrors({
      //       form: "An error occurred while adding the user. Please try again.",
      //     });
    }
  };

  return (
    <div>
      <div id="body" className="theme-green">
        <div className="auth-main">
          <div className="auth_div vivify fadeIn">
            <div className="auth_brand">
              <div className="navbar-brand">
                <img
                  src={Header.Logo_image}
                  width="150"
                  className="d-inline-block mr-2"
                  alt="photos"
                />
              </div>
            </div>
            <div style={{ marginTop: "-55px" }}>{Header.Main_title}</div>
            <div className="card">
              <div className="header">
                <p className="lead">Register Account</p>
              </div>
              <div className="body">
                {/* <button className="btn btn-signin-social">
                  <i className="fa fa-facebook-official facebook-color"></i> Sign in
                  with Facebook
                </button>
                <div className="separator-linethrough">
                  <span>OR</span>
                </div> */}
                <form className="form-auth-small" onSubmit={handleSubmit}>
                  <div className="form-group c_form_group">
                    {isLoading ? <span className="loader"></span> : ""}
                    <label>Sponser Code</label>
                    <input
                      type="text"
                      name="sponsor_code"
                      value={formValue.sponsor_code}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter Sponser Code"
                    />
                  </div>
                  <div className="form-group c_form_group">
                    <label>
                      User Name
                      <span className="text-danger" style={{ margin: "4px" }}>
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="user_name"
                      value={formValue.user_name}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter your name "
                    />
                  </div>
                  {errors.user_name && (
                    <p className="edit_hospital">{errors.user_name}</p>
                  )}
                  <div className="form-group c_form_group">
                    <label>
                      Email
                      <span className="text-danger" style={{ margin: "4px" }}>
                        *
                      </span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formValue.email}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter your email address"
                    />
                  </div>
                  {errors.email && (
                    <p className="edit_hospital">{errors.email}</p>
                  )}
                  <div className="form-group c_form_group">
                    <label>
                      Password
                      <span className="text-danger" style={{ margin: "4px" }}>
                        *
                      </span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      value={formValue.password}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter password"
                    />
                  </div>
                  {errors.password && (
                    <p className="edit_hospital" >{errors.password}</p>
                  )}
                  {/* <div className="form-group c_form_group">
                    <label>Repeat Password</label>
                    <input
                      type="r_password"
                      value={r_password}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter password"
                    />
                  </div> */}
                  <button
                    type="submit"
                    className="btn btn-dark btn-lg btn-block"
                  >
                    Register
                  </button>
                  <div className="bottom">
                    <span>
                      Already have an account? <Link to="/login" style={{ fontWeight: 500 }}>Login</Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* <div className="animate_lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Registration;
